<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="tariffTable"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :tbody-tr-class="rowClass"
                    :items="tariffsWork"
                    :fields="fields"
                    @row-clicked="clicked"
                    @row-contextmenu="handleContextMenuEvent"
                    head-row-variant="primary"
                    >
                    <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(validity_periods)="data">
                            <span 
                            class="reference-field"
                            select-variant="secondary"
                            >
                            (***)
                            </span>
                    </template>
                    <template #cell(sentings)="data">
                        <span 
                        class="reference-field" 
                        >
                            (***)
                        </span>
                    </template>
                    <template #cell(allowance)="data">
                        <span 
                        class="reference-field" 
                        >
                            (***)
                        </span>
                    </template>
                    <template #cell(district_surcharge)="data">
                        <span 
                        class="reference-field" 
                        >
                            (***)
                        </span>
                    </template>
                    <template #cell(class_car)="data">
                        <span @click="getClassCar(data)"
                        class="reference-field" 
                        >
                            (***)
                        </span>
                    </template>
                    <template #cell(route)="data">
                        <span 
                        class="reference-field" 
                        >
                            (***)
                        </span>
                    </template>
                    <template #cell(history_version)="data">
                        <span 
                        class="reference-field" 
                        >
                            ***
                        </span>
                    </template>
                    <template #cell(created_at)="data">
                        <span 
                        >
                            {{ data.item.created_at }}
                        </span>
                    </template>
                    <template #cell(audit)="data">
                        <span 
                        class="reference-field" 
                        >
                            (***)
                        </span>
                    </template>
                    <template #cell(round)="data">
                        <span 
                        class="reference-field" 
                        >
                            (***)
                        </span>
                    </template>
                    <template #cell(is_active)="data">
                        <span>
                            {{data.item.is_active == 1 ? 'Да' : 'Не'}}
                        </span>
                    </template>
                    <template #cell(auto_assignment)="data">
                        <span>
                            {{data.item.auto_assignment == 1 ? 'Да' : 'Не'}}
                        </span>
                    </template>
                    <template #cell(is_send_push_new_order)="data">
                        <span>
                            {{data.item.is_send_push_new_order == 1 ? 'Да' : 'Не'}}
                        </span>
                    </template>
                    <template #cell(price_rounding)="data">
                        <span>
                            {{data.item.price_rounding == 1 ? 'Да' : 'Не'}}
                        </span>
                    </template>
                </b-table>
                <vue-context ref="menu">
                            <li>
                                <b-link
                                class="d-flex align-items-center"
                                @click="blockUnlock(id)"
                                >
                                <feather-icon
                                    icon="CalendarIcon"
                                    size="16"
                                />
                                <span class="ml-75">Блокировать/Разблокировать</span>
                                </b-link>
                            </li>
                            <!-- <li>
                                <b-link
                                class="d-flex align-items-center"
                                
                                >
                                <feather-icon
                                    icon="CopyIcon"
                                    size="16"
                                />
                                <span class="ml-75">Изменить коэффицент увеличения</span>
                                </b-link>
                            </li> -->
                            <!-- <li>
                                <b-link
                                class="d-flex align-items-center"
                                
                                >
                                <feather-icon
                                    icon="CopyIcon"
                                    size="16"
                                />
                                <span class="ml-75">Расписание</span>
                                </b-link>
                            </li> -->
                            <!-- <li>
                                <b-link
                                class="d-flex align-items-center"
                                
                                >
                                <feather-icon
                                    icon="CopyIcon"
                                    size="16"
                                />
                                <span class="ml-75">Указать причину обновления версии</span>
                                </b-link>
                            </li>
                            <li>
                                <b-link
                                class="d-flex align-items-center"
                                
                                >
                                <feather-icon
                                    icon="CopyIcon"
                                    size="16"
                                />
                                <span class="ml-75">Устаеовить периоды действия</span>
                                </b-link>
                            </li> -->
                        </vue-context>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import VueContext from 'vue-context'
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";


export default {
    components: {
        VueContext
    },
    props: ['tariffsWork'],
    data() {
        return {
            fields: [
                { key: 'division', label: 'Подразделение', sortable: true, thStyle: {width: '150px'}},
                { key: 'sort', label: 'Порядок', sortable: true, thStyle: {width: '50px'} },
                { key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '150px'}},
                { key: 'coefficient', label: 'Коэффициент', sortable: true, thStyle: {width: '50px'}},
                { key: 'type_tariff.name', label: 'Тип тарифа', sortable: true, thStyle: {width: '150px'}},
                { key: 'is_active', label: 'Блокирован', sortable: true, thStyle: {width: '50px'}},
                // { key: 'validity_periods', label: 'Периоды действия', sortable: true, thStyle: {width: '50px'}},
                // { key: 'sentings', label: 'Настройки', sortable: true, thStyle: {width: '50px'}},
                // { key: 'allowance', label: 'Надбавка', sortable: true, thStyle: {width: '50px'}},
                // { key: 'district_surcharge', label: 'Наценка на районы', sortable: true, thStyle: {width: '50px'}},
                { key: 'class_car', label: 'Классы авто', sortable: true, thStyle: {width: '50px'}},
                // { key: 'route', label: 'Маршруты', sortable: true, thStyle: {width: '50px'}},
                // { key: 'history_version', label: 'История версий', sortable: true, thStyle: {width: '50px'}},
                { key: 'id', label: 'ID', sortable: true, thStyle: {width: '50px'}},
                { key: 'created_at', label: 'Изменения(время подразделения)', sortable: true, thStyle: {width: '150px'}},
                // { key: 'audit', label: 'Аудит', sortable: true, thStyle: {width: '50px'}},
                { key: 'delivery_price', label: 'Процент коэффициента', sortable: true, thStyle: {width: '50px'} },
                { key: 'min_price', label: 'Цена км', sortable: true, thStyle: {width: '50px'} },
                // { key: 'round', label: 'Октруглять д...', sortable: true, thStyle: {width: '50px'}},
                { key: 'price_km_city', label: 'Разрешить работу коэффициента', sortable: true, thStyle: {width: '50px'} },
                { key: 'price_km_intercity', label: 'Использовать толька тип', sortable: true, thStyle: {width: '50px'} },
                { key: 'description', label: 'Описание', sortable: true, thStyle: {width: '150px'} },
                { key: 'check_in_price', label: 'Цена заезда', sortable: true, thStyle: {width: '50px'} },
                { key: 'free_waiting_of_client_in_minute', label: 'Бесплатное ожидание клиента в минуте', sortable: true, thStyle: {width: '50px'} },
                { key: 'price_hour', label: 'Цена за час', sortable: true, thStyle: {width: '50px'} },
                { key: 'min_penalty', label: 'Минимальная пенсия', sortable: true, thStyle: {width: '50px'} },
                { key: 'price_advertising', label: 'Цена реклама', sortable: true, thStyle: {width: '50px'} },
                { key: 'auto_assignment', label: 'Автоматическое назначение', sortable: true, thStyle: {width: '50px'} },
                { key: 'is_send_push_new_order', label: 'Отправить push-запрос на новый заказ', sortable: true, thStyle: {width: '50px'} },
                { key: 'price_rounding', label: 'Округление цены', sortable: true, thStyle: {width: '50px'} },
                { key: 'radius_auto_assignment', label: 'Радиус авто направления', sortable: true, thStyle: {width: '50px'} },
                { key: 'created_by_login', label: 'Создан логин', sortable: true, thStyle: {width: '150px'} },
            ],
            id: null,
        }
    },
    mounted(){
        resizeable()
    },
    methods: {
        handleContextMenuEvent(item, index, event){
           event.preventDefault();
           this.$refs.menu.open(event)
           this.id = item.id
        }, 
        rowClass(item) {
            if (item.color === null || 'green') return 'table-success'
            else if (item.color === 'white') return 'table-white'
            else if (item.color === 'red') return 'table-danger'
        },
        blockUnlock(id) {
            this.$emit('blockUnlock', id)
        },
        getClassCar(data){
            this.$router.push({name: 'tariff-class-car-settings', query:{filter: data.item.id}})
            this.$store.commit('draggableTab/ADD_TAB',['Классы авто для тарифа ' + data.item.id, `/tariff/tariffClassCar`, data.item.id  ])
        }, 
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index

            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }  
.my-danger{
    background-color: #f9cfcf;
}  
.my-success{
    background-color: #8fe2b4;
} .my-secondary{
    background-color: #dcdddf;
} .my-warning{
    background-color: #ffcd9d;
}   
</style>