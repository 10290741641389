<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-tariff-work  :tariffsWork="tariffsWork" @blockUnlock="blockUnlock" />
        </div>
        <modal-block-unlock :id="id" @refresh="refresh"/>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent" :name="name"></filter-modalVue>
    </div>
</template>

<script>
import tableTariffWork from '@/views/component/Table/tableTariffWork.vue'
import ModalBlockUnlock from '@/views/component/Modal/ModalTariff/ModalBlockUnlock.vue'
    export default {
        props: {
            name: {
                type: String,
                default: '',
            }
        },
        components: {
            tableTariffWork,
            ModalBlockUnlock,
        },
        data() {
            return {
                showPreloader: false,
                tariffsWork: [],
                fields: [
                    { key: 'id', label: 'Id'},
                    { key: 'name', label: 'Имя'},
                    { key: 'division_base_tariff', label: 'Подразделения'},
                    { key: 'sort', label: 'Порядок тарифа'},
                    { key: 'type_tariff_name', label: 'Тип тариф'},
                ],
                id: null,
            }
        },
        mounted() {
            this.openFilter()
            this.$store.commit('pageData/setdataCount', null)
        },
        methods: {
            blockUnlock(id) {
                this.id = id
                this.$bvModal.show('blockUnlock')
            },
            openFilter(){
                let allRoutes = this.$store.state.draggableTab.tabs
                let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                let arrayRoutes = []
                allRoutes.forEach(element => {
                    arrayRoutes.push(element.path)
                });
                if (arrayRoutes.includes(this.$route.path) == true) {
                    if(this.name == 'base-tariffs/working') {
                        this.showPreloader = true
                        this.$http
                        .get(`${this.name}`, {params: param})
                        .then(res => {
                                this.tariffsWork = res.data
                                this.$store.commit('pageData/setdataCount', this.tariffsWork.length)
                                this.showPreloader = false
                                this.$store.commit('REFRESH_DATA', false)
                            })
                    } 
                }else if(arrayRoutes.includes(this.$route.path) == false){
                    if(this.name == 'base-tariffs/working') {
                        this.$bvModal.show(this.$route.name + 'filter')
                    }
                }
            },
            sendToParent(tableData){
                this.tariffsWork = tableData
                this.$store.commit('pageData/setdataCount', this.tariffsWork.length)
            },
            refresh(){
                this.showPreloader = true
                this.$http.get(`${this.name}`)
                    .then(res => {
                        this.tariffsWork = res.data
                        this.$store.commit('pageData/setdataCount', this.tariffsWork.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            },
        },
        computed: {
            fetchingNewData(){
                return this.$store.state.refresh.fetchingNewData
            }
        },
        watch: {
            fetchingNewData(val){
                if (val) {
                    let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                    this.$http
                    .get(`${this.name}`, {params: param})
                    .then(res =>{
                        this.tariffsWork = res.data
                        this.$store.commit('pageData/setdataCount', this.tariffsWork.length)
                        this.$store.commit('REFRESH_DATA', false)
                    })
                }
            }
        },
    }
</script>
